<template>
  <div>
    <ui-component-modal
      :modalTitle="'Edit descriptions'"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveDescription"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :savingSuccessMessage="'Data saved'"
      :savingErrorMessage="'Something went wrong'"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      modalSize="large"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <div key="ready" class="columns">
          <div
            v-for="(textLanguage, index) in mLocation.Descriptions"
            :key="'text_' + index"
            class="column"
          >
            <div class="title is-5">
              {{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}
            </div>
            <div class="">
              <text-editor v-model="textLanguage.Description"></text-editor>
            </div>
          </div>
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'

const TextEditor = () => import('@/components/UI/TextEditor')

export default {
  components: {
    'text-editor': TextEditor,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mLocation: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    let self = this
    this.mLocation = JSON.parse(JSON.stringify(self.location))
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    saveDescription() {
      let self = this
      self.isSaving = true

      locationProvider.methods
        .saveLocationData(self.mLocation)
        .then((response) => {
          if (response.status === 200) {
            self.setLocation(self.mLocation)

            self.isSavingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((e) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
<style lang="scss" scoped></style>
